// react/gatsby imports
import { useStaticQuery, graphql } from "gatsby";

const useInDesignQuery = () => {
  const prismicData = useStaticQuery(graphql`
    query granichInDesignQuery {
      course: prismicCoursetype(
        data: { coursetitle: { text: { eq: "Granich InDesign" } } }
      ) {
        uid
        data {
          body {
            ... on PrismicCoursetypeDataBodyTags {
              id
              slice_type
              items {
                coursetag {
                  text
                }
              }
            }
          }
          coursetitle {
            text
          }
          coursedescription {
            text
          }
          coursestartandend {
            text
          }
          coursestatus
          coursestream
          coursetype
          courseprice
          coursemainimage {
            gatsbyImageData
          }
        }
      }
      content: allPrismicContent(
        sort: { fields: data___contentordernumber, order: ASC }
      ) {
        nodes {
          uid
          id
          data {
            body {
              ... on PrismicContentDataBodyTags {
                id
                slice_type
                items {
                  contenttag {
                    text
                  }
                }
              }
            }
            contentordernumber
            contenttype
            contenttitle {
              text
            }
            contentdescription {
              text
            }
            contentimage {
              gatsbyImageData
            }
            contentyoutubevideolink {
              url
            }
            contentlinkpinterest {
              url
            }
            contentlinkbehance {
              url
            }
            contentlinkmedium {
              url
            }
            contentpdf {
              url
            }
            contentyoutubetiming {
              text
            }
          }
        }
      }
      page: prismicPage(uid: { eq: "granich-indesign" }) {
        uid
        data {
          seodescription {
            text
          }
          seoimage {
            url(imgixParams: { width: 1200 })
            thumbnails {
              vk {
                url(imgixParams: { width: 510 })
              }
            }
          }
        }
      }
    }
  `);

  return prismicData;
};

export default useInDesignQuery;
