/* eslint-disable */
module.exports = {
  consultingAccessibility: {
    indesignConsultingAccessibility: true,
  },
  offer: null,
  curriculumHeader: {
    title: "Программа мастер-класса",
  },
  curriculum: [
    {
      title: "Интерфейс и фреймы",
      tagName: "1 урок",
      videoColumn: "https://www.youtube.com/embed/z_UP8vI1AYw",
    },
    {
      title: "Стили, страницы, шрифты",
      tagName: "2 урок",
      videoColumn: "https://www.youtube.com/embed/BU9axqbH-MA",
    },
    {
      title: "Управление цветом",
      tagName: "3 урок",
      videoColumn: "https://www.youtube.com/embed/drQW40jBiA4",
    },
  ],
};
