import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import HomeworkAndConsultingModal from "../modal";
import * as SC from "./style";

const Homework = () => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <SC.Wrapper>
      <SC.Title>Домашнее задание</SC.Title>
      <SC.Footer>
        <Modal
          center
          closeIcon={<div />}
          open={modalIsOpen}
          onClose={closeModal}
          focusTrapped={false}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal",
          }}
        >
          <HomeworkAndConsultingModal
            formId="ltForm3034275"
            formPostUrl="https://school.granich.design/pl/lite/block-public/process-html?id=1103520643"
            title="Получить ДЗ"
            buttonTitle={
              <>
                <SC.StyledDownloadIcon />
                Получить ДЗ
              </>
            }
            openModal={openModal}
            closeModal={closeModal}
            modalIsOpen={modalIsOpen}
            googleAnalyticsCategory="Отправка формы Получить ДЗ (Granich InDesign)"
          />
        </Modal>
        <SC.DownloadButton type="submit" id="button9712993" onClick={openModal}>
          <SC.StyledDownloadIcon />
          Получить ДЗ*
        </SC.DownloadButton>
        <SC.Notice>
          *Нужно залогиниться в учебный кабинет или создать его
        </SC.Notice>
      </SC.Footer>
    </SC.Wrapper>
  );
};

export default Homework;
