import React from "react";
import { Container } from "components/style";
import IndesignIcon from "assets/svgs/indesign-icon.svg";
import GranichLogo from "assets/svgs/granich-logo_color.svg";
import * as SC from "./style";

const IndesignOffer = () => (
  <SC.Section>
    <Container>
      <SC.Wrapper>
        <SC.LogoWrapper>
          <GranichLogo />
          <IndesignIcon />
        </SC.LogoWrapper>

        <SC.Title>
          <span>Granich InDesign</span>
          <i>бесплатный мастер-класс</i>
        </SC.Title>
        <SC.Descr>
          <span>
            Всё важное, что вам следует знать о программе Adobe InDesign
            + закрепление практикой
          </span>
        </SC.Descr>
      </SC.Wrapper>
    </Container>
  </SC.Section>
);

export default IndesignOffer;
