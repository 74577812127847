import React from "react";
import { Container } from "components/style";
import * as SC from "./HomeworkAndConsulting";
import "react-responsive-modal/styles.css";
import Homework from "./homework";

const HomeworkAndConsulting = () => (
  <SC.Section>
    <Container>
      <SC.Wrapper>
        <Homework />
      </SC.Wrapper>
    </Container>
  </SC.Section>
);

export default HomeworkAndConsulting;
