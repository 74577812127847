// react/gatsby imports
import React from "react";
// assets
import ogImage from "assets/images/seo/granich-indesign.jpg";
import ogImageVK from "assets/images/seo/vk/granich-indesign.jpg";
// data
import localData from "data/pages/granich-indesign";
// utilities
import generateCanonicalLink from "utilities/link-utils";
// constants
import PAGE_SLUGS from "constants/page-slugs";
// hooks
import useInDesignQuery from "hooks/graphql/useInDesignQuery";
// components
import Layout from "components/layout";
import SEO from "components/layout/seo";
import IndesignOffer from "components/pages/granich-indesign/offer";
import HomeworkAndConsulting from "components/pages/granich-indesign/homework-and-consulting";
import ContentCard from "components/pages/index/precourse-preparation/content-card";
import CourseCurriculum from "components/pages/course-page/lessons-order";
import { ContentCardsWrapper } from "components/pages/course-page/lessons-order/styles";

// 21.12.2023 Ссылку на страницу Индизайна скрыли с главной, но страницу оставили в доступе, чтобы люди на неё с ютуба Школы могли заходить
const GranichInDesign = () => {
  const prismicData = useInDesignQuery();
  const { data } = prismicData.course;
  const { data: seoData } = prismicData.page || {};
  const curriculumDataWithContentCards = [
    ...localData.curriculum,
    {
      title: "Дополнительные уроки",
      tagName: "4 урок",
      customContent: (
        <ContentCardsWrapper>
          {prismicData.content.nodes
            .filter(({ data: dat }) => {
              const condition =
                dat.contenttitle.text === "Как оформить абзац текста" ||
                dat.contenttitle.text === "Виды гарнитур (шрифтов)" ||
                dat.contenttitle.text === "Как построить сетку. Часть 1";
              return condition;
            })
            .map((el, idx) => (
              <ContentCard content={el} key={idx} />
            ))}
        </ContentCardsWrapper>
      ),
    },
  ];

  return (
    <Layout>
      <SEO
        title="Granich InDesign"
        description="Всё важное, что вам следует знать о программе Adobe InDesign + закрепление практикой"
        socialDescription={
          seoData?.seodescription?.text ||
          "Всё важное, что вам следует знать о программе Adobe InDesign + закрепление практикой"
        }
        keywords={[
          "indesign",
          "free",
          "бесплатный",
          "course",
          "гранич",
          "granich",
          "курс",
          "индизайн",
        ]}
        ogImage={seoData?.seoimage?.url || ogImage}
        ogImageVk={seoData?.seoimage?.thumbnails?.vk?.url || ogImageVK}
        url={generateCanonicalLink(PAGE_SLUGS.granichInDesign)}
      />
      <div className="section-top-block" />
      <IndesignOffer data={data} />
      <CourseCurriculum
        dataHeader={localData.curriculumHeader}
        data={curriculumDataWithContentCards}
      />
      <HomeworkAndConsulting
        consultingAccessibility={localData.consultingAccessibility}
      />
    </Layout>
  );
};

export default GranichInDesign;
