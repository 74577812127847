import React from "react";
import * as SC from "../style";

const PinterestContent = ({
  content: { data },
  pinterestIcon,
  pinterestLinkIcon,
  contentExternalLink,
  tags,
}) => (
  <SC.WrapperExternalLink
    className="content"
    pinterest
    rel="noopener noreferrer"
    href={contentExternalLink}
    target="_blank"
  >
    <SC.Container>
      <SC.TagList>
        {tags.map((contentTag, idx) => (
          <SC.Tag key={idx}>{contentTag}</SC.Tag>
        ))}
      </SC.TagList>
      <SC.Image image={data.contentimage.gatsbyImageData} />
      <SC.Title>{data.contenttitle.text}</SC.Title>
      <SC.Descr>{data.contentdescription.text}</SC.Descr>
    </SC.Container>
    <SC.Button pinterest>
      <SC.ButtonText>Изучить</SC.ButtonText>
      <SC.IconsWrapper>
        <SC.PinterestIcon fluid={pinterestIcon} />
        <SC.PinterestOutIcon fluid={pinterestLinkIcon} />
      </SC.IconsWrapper>
    </SC.Button>
  </SC.WrapperExternalLink>
);

export default PinterestContent;
