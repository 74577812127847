import React from "react";
import * as SC from "../style";

const PodborkaContent = ({
  content: { data, uid },
  podborkaLinkIcon,
  tags,
}) => (
  <SC.WrapperLink
    className="content"
    podborka
    href={`/${uid}`}
    target="_blank"
    type={data.contenttype}
  >
    <SC.Container>
      <SC.TagList>
        {tags.map((contentTag, idx) => (
          <SC.Tag key={idx}>{contentTag}</SC.Tag>
        ))}
      </SC.TagList>
      <SC.Image image={data.contentimage.gatsbyImageData} />
      <SC.Title>{data.contenttitle.text}</SC.Title>
      <SC.Descr>{data.contentdescription.text}</SC.Descr>
    </SC.Container>
    <SC.Button podborka>
      <SC.ButtonText>Изучить</SC.ButtonText>
      <SC.IconsWrapper>
        <SC.PodborkaOut fluid={podborkaLinkIcon} />
      </SC.IconsWrapper>
    </SC.Button>
  </SC.WrapperLink>
);

export default PodborkaContent;
