import React from "react";
import * as SC from "../style";

const YoutubeMixedPinterestContent = ({
  content: { data, uid },
  youtubePng,
  pinterestIcon,
  tags,
}) => (
  <SC.WrapperLink
    className="content"
    href={`/${uid}`}
    target="_blank"
    type={data.contenttype}
  >
    <SC.Container>
      <SC.TagList>
        {tags.map((contentTag, idx) => (
          <SC.Tag key={idx}>{contentTag}</SC.Tag>
        ))}
      </SC.TagList>
      <SC.Image image={data.contentimage.gatsbyImageData} />
      <SC.Title>{data.contenttitle.text}</SC.Title>
    </SC.Container>
    <SC.Button mixed>
      <SC.ButtonWatchBlockWrapper>
        <SC.ButtonBlockTitle>Посмотреть</SC.ButtonBlockTitle>
        <SC.ButtonWatchBlock>
          <SC.YoutubeMixedIcon fluid={youtubePng} />
          {data.contentyoutubetiming && (
            <SC.ButtonMixedTiming>
              {data.contentyoutubetiming.text}
            </SC.ButtonMixedTiming>
          )}
        </SC.ButtonWatchBlock>
      </SC.ButtonWatchBlockWrapper>
      <SC.ButtonReadBlockWrapper mixedPinterest>
        <SC.ButtonBlockTitle>Изучить</SC.ButtonBlockTitle>
        <SC.ButtonReadBlock>
          <SC.BehanceMixedIcon fluid={pinterestIcon} />
        </SC.ButtonReadBlock>
      </SC.ButtonReadBlockWrapper>
    </SC.Button>
  </SC.WrapperLink>
);

export default YoutubeMixedPinterestContent;
