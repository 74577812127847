import React, { useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Cross from "assets/svgs/cross.svg";
import ShakeForm from "components/common/shake-form";
import useGetCourseFormEncrypt from "hooks/useGetCourseFormEncrypt";

import {
  Form,
  FormCross,
  FormTitle,
  FormWrapper,
  FormContainer,
  FormInput,
  FormPolitikaLabel,
  ErrorMessage,
  FormButtonBlock,
  FormButton,
  FormFooterWrapper,
  FormFooterContainer,
  CustomBr,
  FormPrice,
} from "./style";

const HomeworkAndConsultingModal = ({
  closeModal,
  title,
  formPostUrl,
  formId,
  buttonTitle,
  price,
}) => {
  const formEl = useRef(null);
  const submitForm = () => {
    formEl.current.submit();
  };

  const myNameNewRegExp = /([^\d€\/\\.?":@#$])/;

  const [shakeTrigger, setShakeTrigger] = useState(3);
  function isInitialValid(props) {
    if (!props.validationSchema) return true;
    return props.validationSchema.isValidSync(props.initialValues);
  }

  const { getCourseTags, getCourseButtonOnClick } = useGetCourseFormEncrypt();

  return (
    <>
      <ShakeForm pose={["shake"]} poseKey={shakeTrigger}>
        <Formik
          isInitialValid={isInitialValid}
          initialValues={{
            formParams: {
              first_name: "",
              last_name: "",
              email: "",
            },
          }}
          onSubmit={submitForm}
          validationSchema={Yup.object().shape({
            formParams: Yup.object().shape({
              first_name: Yup.string()
                .trim("Уберите пробелы в начале и в конце строки")
                .strict()
                .matches(myNameNewRegExp, "Неверные символы в Имени")
                .required("Сперва впишите Имя"),
              last_name: Yup.string()
                .trim("Уберите пробелы в начале и в конце строки")
                .strict()
                .matches(myNameNewRegExp, "Неверные символы в Фамилии")
                .required("Сперва впишите Фамилию"),
              email: Yup.string()
                .email("Это не Электропочта")
                .required("Сперва впишите Электропочту"),
            }),
          })}
        >
          {props => {
            const {
              values,
              errors,
              isSubmitting,
              handleChange,
              handleSubmit,
              isValid,
              touched,
              handleBlur,
            } = props;
            return (
              <Form
                ref={formEl}
                onSubmit={e => {
                  if (isValid) {
                    handleSubmit(e);
                  } else {
                    handleSubmit(e);
                    setShakeTrigger(shakeTrigger + 1);
                  }
                }}
                id={formId}
                action={formPostUrl}
                method="post"
                data-open-new-window="0"
              >
                <FormWrapper>
                  <FormCross onClick={closeModal}>
                    <Cross />
                  </FormCross>

                  <FormContainer>
                    <FormTitle>{title}</FormTitle>
                    <input type="hidden" name="formParams[setted_offer_id]" />
                    <FormInput
                      type="text"
                      maxLength="60"
                      placeholder="Имя"
                      name="formParams[first_name]"
                      value={values.formParams.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorStyle={
                        errors.formParams &&
                        errors.formParams.first_name &&
                        touched.formParams &&
                        touched.formParams.first_name
                          ? 1
                          : 0
                      }
                    />{" "}
                    <br />
                    {errors.formParams &&
                      errors.formParams.first_name &&
                      touched.formParams &&
                      touched.formParams.first_name && (
                        <ErrorMessage>
                          {errors.formParams.first_name}
                        </ErrorMessage>
                      )}
                    <FormInput
                      type="text"
                      maxLength="60"
                      placeholder="Фамилия"
                      name="formParams[last_name]"
                      value={values.formParams.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorStyle={
                        errors.formParams &&
                        errors.formParams.last_name &&
                        touched.formParams &&
                        touched.formParams.last_name
                          ? 1
                          : 0
                      }
                    />{" "}
                    <br />
                    {errors.formParams &&
                      errors.formParams.last_name &&
                      touched.formParams &&
                      touched.formParams.last_name && (
                        <ErrorMessage>
                          {errors.formParams.last_name}
                        </ErrorMessage>
                      )}
                    <FormInput
                      type="email"
                      maxLength="60"
                      placeholder="Электропочта"
                      name="formParams[email]"
                      value={values.formParams.email}
                      onChange={handleChange}
                      id="formParams[email]"
                      onBlur={handleBlur}
                      errorStyle={
                        errors.formParams &&
                        errors.formParams.email &&
                        touched.formParams &&
                        touched.formParams.email
                          ? 1
                          : 0
                      }
                    />
                    <br />
                    {errors.formParams &&
                      errors.formParams.email &&
                      touched.formParams &&
                      touched.formParams.email && (
                        <ErrorMessage>{errors.formParams.email}</ErrorMessage>
                      )}
                    <FormPolitikaLabel>
                      Нажимая на кнопку в этой форме, я принимаю условия{" "}
                      <a target="_blank" href="/privacy">
                        {" "}
                        политики конфиденциальности
                      </a>
                      <br />
                      <CustomBr />и{" "}
                      <a target="_blank" href="/public-offer">
                        {" "}
                        учебного договора
                      </a>
                    </FormPolitikaLabel>
                  </FormContainer>
                </FormWrapper>{" "}
                <FormFooterWrapper>
                  <FormFooterContainer>
                    <FormButtonBlock>
                      <FormButton
                        disabled={isSubmitting}
                        type="submit"
                        id="button9387340"
                        onClick={getCourseButtonOnClick}
                      >
                        {buttonTitle}
                      </FormButton>
                      {price && <FormPrice>{price}</FormPrice>}
                    </FormButtonBlock>
                  </FormFooterContainer>
                </FormFooterWrapper>
                {getCourseTags}
              </Form>
            );
          }}
        </Formik>
      </ShakeForm>
    </>
  );
};

export default HomeworkAndConsultingModal;
