import React from "react";
import * as SC from "../style";

const BehanceContent = ({
  content: { data },
  behanceIcon,
  behanceLinkIcon,
  contentExternalLink,
  tags,
}) => (
  <SC.WrapperExternalLink
    className="content"
    behance
    rel="noopener noreferrer"
    href={contentExternalLink}
    target="_blank"
  >
    <SC.Container>
      <SC.TagList>
        {tags.map((contentTag, idx) => (
          <SC.Tag key={idx}>{contentTag}</SC.Tag>
        ))}
      </SC.TagList>
      <SC.Image image={data.contentimage.gatsbyImageData} />
      <SC.Title>{data.contenttitle.text}</SC.Title>
      <SC.Descr>{data.contentdescription.text}</SC.Descr>
    </SC.Container>
    <SC.Button behance>
      <SC.ButtonText>Изучить</SC.ButtonText>
      <SC.IconsWrapper>
        <SC.BehanceIcon fluid={behanceIcon} />
        <SC.BehanceOutIcon fluid={behanceLinkIcon} />
      </SC.IconsWrapper>
    </SC.Button>
  </SC.WrapperExternalLink>
);

export default BehanceContent;
