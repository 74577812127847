import React from "react";
import * as SC from "../style";

const YoutubeContent = ({
  content: { data, uid },
  youtubePng,
  pinterestLinkIcon,
  tags,
}) => (
  <SC.WrapperLink
    className="content"
    href={`/${uid}`}
    target="_blank"
    type={data.contenttype}
  >
    <SC.Container>
      <SC.TagList>
        {tags.map((contentTag, idx) => (
          <SC.Tag key={idx}>{contentTag}</SC.Tag>
        ))}
      </SC.TagList>
      <SC.Image image={data.contentimage.gatsbyImageData} />
      <SC.Title>{data.contenttitle.text}</SC.Title>
    </SC.Container>
    <SC.Button youtube>
      <SC.ButtonYoutubeTextWrapper>
        <SC.ButtonYoutubeText>Посмотреть</SC.ButtonYoutubeText>
        {data.contentyoutubetiming && (
          <SC.ButtonYoutubeTiming>
            {data.contentyoutubetiming.text}
          </SC.ButtonYoutubeTiming>
        )}
      </SC.ButtonYoutubeTextWrapper>
      <SC.YoutubeIconsWrapper>
        <SC.YoutubeIcon fluid={youtubePng} />
        <SC.PinterestOutIcon fluid={pinterestLinkIcon} />
      </SC.YoutubeIconsWrapper>
    </SC.Button>
  </SC.WrapperLink>
);

export default YoutubeContent;
