import React from "react";
import BehanceContent from "./content-cards/Behance";
import MediumContent from "./content-cards/Medium";
import PinterestContent from "./content-cards/Pinterest";
import PodborkaContent from "./content-cards/Podborka";
import YoutubeContent from "./content-cards/Youtube";
import YoutubeMixed from "./content-cards/YoutubeMixed";
import YoutubeMixedPinterestContent from "./content-cards/YoutubeMixedPinterest";
import useLocalQuery from "./useLocalQuery";

const contentTypes = {
  Youtube: "Youtube",
  Behance: "Behance",
  Pinterest: "Pinterest",
  Medium: "Medium",
  Mixed: "Youtube + Medium + Behance",
  MixedPinterest: "Youtube + Pinterest",
  Podborka: "Осознанная подборка",
};

const ContentCard = ({ content }) => {
  const icons = useLocalQuery();
  const { data } = content;

  const tags =
    data.body
      .find(el => el.slice_type === "tags")
      ?.items.map(({ contenttag }) => contenttag.text) || [];

  const findContentExternalLink = contentType => {
    switch (contentType) {
      case contentTypes.Pinterest:
        return data.contentlinkpinterest.url;
      case contentTypes.Behance:
        return data.contentlinkbehance.url;
      case contentTypes.Medium:
        return data.contentlinkmedium.url;
      default:
        return "/";
    }
  };

  return (
    <div>
      {data.contenttype === contentTypes.Mixed && (
        <YoutubeMixed
          content={content}
          behanceIcon={icons.behanceIcon}
          youtubePng={icons.youtubePng}
          mediumIcon={icons.mediumIcon}
          tags={tags}
        />
      )}
      {data.contenttype === contentTypes.Youtube && (
        <YoutubeContent
          pinterestLinkIcon={icons.pinterestLinkIcon}
          content={content}
          youtubePng={icons.youtubePng}
          tags={tags}
        />
      )}
      {data.contenttype === contentTypes.Behance && (
        <BehanceContent
          contentExternalLink={findContentExternalLink(data.contenttype)}
          behanceLinkIcon={icons.behanceLinkIcon}
          content={content}
          behanceIcon={icons.behanceIcon}
          tags={tags}
        />
      )}
      {data.contenttype === contentTypes.Pinterest && (
        <PinterestContent
          contentExternalLink={findContentExternalLink(data.contenttype)}
          pinterestLinkIcon={icons.pinterestLinkIcon}
          content={content}
          pinterestIcon={icons.pinterestIcon}
          tags={tags}
        />
      )}
      {data.contenttype === contentTypes.Medium && (
        <MediumContent
          contentExternalLink={findContentExternalLink(data.contenttype)}
          mediumLinkIcon={icons.mediumLinkIcon}
          content={content}
          mediumIcon={icons.mediumIcon}
          tags={tags}
        />
      )}
      {data.contenttype === contentTypes.Podborka && (
        <PodborkaContent
          podborkaLinkIcon={icons.podborkaLinkIcon}
          content={content}
          tags={tags}
        />
      )}
      {data.contenttype === contentTypes.MixedPinterest && (
        <YoutubeMixedPinterestContent
          content={content}
          youtubePng={icons.youtubePng}
          pinterestIcon={icons.pinterestIcon}
          tags={tags}
        />
      )}
    </div>
  );
};

export default ContentCard;
